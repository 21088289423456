@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sen:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

#root,
html,
body {
	overflow: hidden;
}

.main-logo {
	background-image: url("../assets/images/logo-footer.webp");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.loading-wheel {
	background-image: url("../assets/images/loading-wheel.svg");
	background-repeat: no-repeat;
	background-size: 80%;
	background-position: center;
}

.placeholder-waves {
	background-image: url("../assets/images/placeholder-waves.webp");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.stone-left {
	background-image: url("../assets/images/stone-left.webp");
	background-repeat: no-repeat;
	background-position: top left;

	transition: transform 1s ease;
	transform: translateX(-60px);

	&.animate {
		transform: translateX(0);
	}
}

.stone-right {
	background-image: url("../assets/images/stone-right.webp");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;

	width: calc(100vw + 50px);

	transition: transform 1s ease;
	transform: translateX(50px);

	&.animate {
		transform: translateX(0px);
	}
}

.icon-back {
	background-image: url("../assets/images/back.svg");
	background-repeat: no-repeat;
	background-position: center;
  animation: icon-back-animation 2s ease infinite;
}

@keyframes icon-back-animation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}

.icon-more {
	background-image: url("../assets/images/more.svg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.image-mariam {
	background-image: url("../assets/images/mariam.webp");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.image-marker {
	background-image: url("../assets/images/marker.webp");
	background-repeat: no-repeat;
	background-size: contain;
	transition: background-position 0.8s ease;
	&.animate-element {
		background-position: 0 -100px;
	}
	&.animate {
		background-position: 0 0;
	}
}

@media (max-width: 650px) {
	.image-marker {
    background-image: url("../assets/images/marker-phone.webp");
	}
}

.image-logo-small {
	background-image: url("../assets/images/small-logo.webp");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.image-logo-white {
	background-image: url("../assets/images/white-logo.svg");
	background-repeat: no-repeat;
	background-position: center;

	background-size: 300vh;
	transition: background-size 0.8s ease;
	&.animate {
		background-size: 220vh;
	}
}

@media (max-width: 1024px) {
	.image-logo-white {
		background-size: 140vh;
		transition: background-size 0.8s ease;
		&.animate {
			background-size: 100vh;
		}
	}
}

.image-dark-logo {
	background-image: url("../assets/images/logo-footer.webp");
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.icon-expand {
	display: flex;
	align-items: center;
	justify-content: center;

	& div {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	& span {
		transition: all 0.2s ease;
		display: inline-block;
		height: 3px;
		background-color: #fff;

		&:first-child {
			width: 20px;
			margin-bottom: 5px;
		}
		&:last-child {
			width: 14px;
		}
	}
}

.icon-animated {
	& span {
		&:first-child {
			transform: translateY(4px) rotate(-45deg);
		}
		&:last-child {
			width: 20px !important;
			transform: translateY(-4px) rotate(45deg);
		}
	}
}

.animation-circles {
  opacity: 0;
  transition: opacity .2s ease;
	& span {
		position: fixed;
		top: calc(50% + 44px);
		left: 50%;
		display: inline-block;
		width: 80vh;
		height: 80vh;
		border: 42px solid #fff;
		border-radius: 50%;
		transition: transform 1.4s ease, opacity 1s ease;
	}
	& .animation-circle {
		&-2 {
      opacity: .8;
			transform: translate(-50%, -50%) scale(.5);
		}
	}

	&.animate {
    opacity: 1;
		& .animation-circle {
			&-2 {
				transform: translate(-50%, -50%) scale(1.1);
			}
		}
	}
}

@media (max-width: 1024px) {
	.animation-circles > span {
		width: 90vw;
		height: 90vw;
		border: 32px solid #fff;
	}
	.animation-circles {
    opacity: 0;
		& .animation-circle {
			&-2 {
        opacity: 1;
				transform: translate(-50%, -50%) scale(1);
			}
		}

		&.animate {
			& .animation-circle {
				&-2 {
					opacity: 0.8;
          transform: translate(-50%, -50%) scale(1);
				}
			}
		}
	}
}

video {
	box-sizing: border-box;
	height: 56.25vw;
	left: 50%;
	min-height: 100%;
	min-width: 100%;
	transform: translate(-50%, -50%);
	position: fixed;
	top: 50%;
	width: 177.77777778vh;
	object-fit: cover;
  z-index: -80;
}

.navbar-hidden {
	transform: translateY(-100%) !important;
}

.animation-padding {
  animation: padding-animation 1s ease infinite;
}

@keyframes padding-animation {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
